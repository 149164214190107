import { DEVICES } from '../../../../device';
import { MAX_PHONE_BG_SIZE, SHAPE_POSITIONS } from '../constants';

export const checkIsMobileMode = ({
  shapeElementsLength,
  itemSize,
  device,
}) => {
  const isTablet = device === DEVICES.TABLET;
  const isPhone = device === DEVICES.PHONE;

  if (!isPhone && !isTablet) return false;

  if (itemSize <= 8 && isTablet) return false;

  if (itemSize <= 4 && isPhone) return false;

  return shapeElementsLength > 1;
};

export const getBackgroundStyles = ({
  position,
  backgroundSize,
  device,
  isMobileMode,
}) => {
  const isMobileStyles = isMobileMode && (device === DEVICES.PHONE || device === DEVICES.TABLET);
  const isPhone = device === DEVICES.PHONE;

  switch (position) {
    case SHAPE_POSITIONS.LEFT:
      return isMobileStyles
        ? {
          width: '100%',
          height: `${backgroundSize > MAX_PHONE_BG_SIZE && isPhone
            ? MAX_PHONE_BG_SIZE
            : backgroundSize
          }%`,
        }
        : {
          width: `${backgroundSize}%`,
          height: '100%',
        };
    case SHAPE_POSITIONS.RIGHT:
      return isMobileStyles
        ? {
          position: 'absolute',
          top: 'auto',
          bottom: '0',
          height: `${backgroundSize > MAX_PHONE_BG_SIZE && isPhone
            ? MAX_PHONE_BG_SIZE
            : backgroundSize
          }%`,
          width: '100%',
        }
        : {
          position: 'absolute',
          left: 'auto',
          right: '0',
          width: `${backgroundSize}%`,
          height: '100%',
        };
    case SHAPE_POSITIONS.TOP:
      return {
        height: `${backgroundSize}%`,
        width: '100%',
      };
    case SHAPE_POSITIONS.BOTTOM:
      return {
        position: 'absolute',
        top: 'auto',
        bottom: 0,
        height: `${backgroundSize}%`,
        width: '100%',
      };
    default:
      return null;
  }
};
