import getStateValue from '../../helpers/getStateValue';
import dom from '../../wrapper/DomWrapper';

import { deleteIntervally } from './utils';

export default () => {
  const isBackupMode = getStateValue('isBackup', false);

  if (!isBackupMode) return;

  const headerSection = dom.getElement('.header');
  const siteSection = dom.getElement('.layout.main');
  const footerSection = dom.getElement('.footer');
  const siteSectionLinks = siteSection ? dom.getCollection('a', siteSection) : [];
  const footerSectionLinks = footerSection ? dom.getCollection('a', footerSection) : [];
  const basketLink = dom.getElement('.basket', headerSection);

  const allInnerLinksExceptMenu = [
    ...(siteSectionLinks || []),
    ...(footerSectionLinks || []),
  ];

  if (basketLink) dom.updateStyle(basketLink, { pointerEvents: 'none' });

  allInnerLinksExceptMenu.forEach((link) => {
    dom.updateStyle(link, {
      pointerEvents: 'none',
    });
  });

  deleteIntervally('.ecom-catalogue', ['.ecom-catalogue__product', '.ecom-catalogue__pagination-list > li']);
  deleteIntervally('.layout.main', ['.ecom-catalogue__product']);
  deleteIntervally('.blog-grid', ['a']);
  deleteIntervally('.share', ['.share__btn']);
};
