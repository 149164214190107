import isEmpty from 'lodash/isEmpty';

import dom from '../../wrapper/DomWrapper';

import { MAX_TRY_COUNT } from './constants';

export const deleteIntervally = (sectionSelector, elements = []) => {
  let tryCount = 1;

  if (!sectionSelector || tryCount > MAX_TRY_COUNT) return;

  const section = dom.getElement(sectionSelector);

  if (!section) return;

  const linksInterval = setInterval(() => {
    const collection = elements.reduce((acc, selector) => {
      const elementsForDisable = dom.getCollection(selector, section);

      if (isEmpty(elements)) return acc;

      return [...acc, ...elementsForDisable];
    }, []);

    if (isEmpty(collection)) {
      tryCount += 1;
    } else {
      clearInterval(linksInterval);

      collection.forEach((el) => {
        // eslint-disable-next-line no-param-reassign
        if (el?.tagName === 'A') el.href = '#';

        dom.updateStyle(el, {
          pointerEvents: 'none',
        });
      });
    }
  }, 700);
};
